<template>
  <div class="card">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h2 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>Terms List</h2>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="terms-table"
             class="table table-borderless table-condensed"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-width="100" data-field="code" >Code</th>
          <th data-field="name" >Name</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Delete</a>
      </div>
    </div>
    <!-- End of Context Menu -->


    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

    <vmodal name="terms-window"  transition="nice-modal-fade" :delay="100" :resizable="true" width="75%" :height="525" @before-open="beforeOpen" @before-close="beforeClose" >
      <div class="card">

        <div class="card-title" style="padding-top: 20px; padding-right: 20px;padding-left: 20px; padding-bottom: 0px;">
          <div class="form-group" >
            <label>Name:</label>
            <input type="text" class="form-control" placeholder="Enter Terms Name here..."  autocomplete="off" maxlength="100" v-if="terms" v-model="terms.name">
          </div>
        </div>

        <div class="card-body" style="padding-top: 0px;padding-bottom: 0px;">
          <TermsForm  v-if="terms" v-bind:mydata="terms.data" ></TermsForm>
        </div>

        <div class="card-footer" style="padding-top: 10px;padding-bottom: 0px;">
          <div class="text-right" >
            <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>

      </div>

    </vmodal>

  </div>

</template>

<script>

import TermsForm from '@/components/v1/terms/TermsForm.vue'
import {userService} from '@/store/auth-header.js'
export default {
  name: 'TermsView',
  components: {
    TermsForm
  },
  data () {
    return {
      terms: JSON.parse('{"id":"","code":0,"type":0,"name":"","data":" "}'),
      mytable: {},
      editor: null
    }
  },
  created () {
    let self = this;

  },
  mounted () {
    let self = this;

    this.$data.mytable = $('#terms-table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    $('#terms-table').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        var id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyTerms(id);
        }else if ($(e.target).text() === 'Delete') {
          self.removeTerms(id);
        }
      }
    });

    $('#txtsearch').keyup(function () {
      var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#terms-table>tbody>tr').show().filter(function () {
        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
        return !~text.indexOf(val)
      }).hide();

    });

    self.loadData();

  },
  methods:{
    beforeOpen(){
      // console.log('beforeOpen');
    },
    beforeClose(){

    },
    showModal () {
      this.$modal.show('terms-window');
    },
    modifyTerms (id) {
      let self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.terms = {};
      fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/terms/${id}/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          self.$data.terms = data.data;
          self.$modal.show('terms-window');
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });
    },
    removeTerms (id) {
      let self = this;

      self.$data.terms.id = id;

      const requestOptions = {
        method: 'DELETE',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.group)
      };

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete tms!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/terms/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('terms-window');
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            );
          }).catch(function (err) {
            swal({ title: "Oops", text: err.toString(), type: "error" });
          });
        }
      });

    },

    loadData() {
      let self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.mytable.bootstrapTable('load',[]);

      fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/terms/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          self.$data.mytable.bootstrapTable('load', data.data);
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });
    },
    saveDocument(){
      let self = this;

      self.$data.terms.data = tinymce.get('content-editor').getContent();

      if(self.$data.terms.name.length < 1){
        alert("Invalid Name");
        return
      }

      const requestOptions = {
        method:  (self.$data.terms.code == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.terms)
      };

      fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/terms/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
          self.terms = JSON.parse('{"id":"","code":0,"type":0,"name":"","data":""}');
          self.$modal.hide('terms-window');
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });

    }
  }
}
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
