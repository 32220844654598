<template>
  <div>
    <textarea id="content-editor" v-if="data" v-model="data" > </textarea>
  </div>
</template>

<script>
  import {moment} from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'TermsForm',
    components: {

    },
    props: {
      mydata: '',
    },

    data () {
      return {
        data: ''
      }
    },
    beforeMount () {
      this.data = this.mydata; // save props data to itself's data and deal with tms
    },
    created () {
      let self = this;

    },
    mounted () {
      let self = this;
      if(tinymce.get('content-editor') !== null){
        tinyMCE.remove();
      }
      tinymce.init({
        selector: 'textarea',
        height: 350,
        plugins: 'print preview fullpage code powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons',
        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat ',
        menubar: 'file edit view insert format tools table tc',
      });
    },
    methods: {
      initEditor () {
        let self = this;
        try {
          tinymce.init({
            selector: "#content-editor",
            images_upload_credentials: true,
            image_title: true,
            automatic_uploads: true,
            width: '100%',
            height: 250,
            plugins: ["advlist autolink autosave link image lists charmap print preview hr anchor pagebreak",
              "searchreplace wordcount code visualblocks visualchars fullscreen insertdatetime media nonbreaking",
              "table contextmenu directionality emoticons template textcolor paste fullpage textcolor colorpicker textpattern noneditable"
            ],
            toolbar1: "print |bullist numlist outdent indent | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect formatselect fontselect fontsizeselect",
            statusbar: true,
            menubar: true,
            file_picker_types: 'image',
            file_picker_callback: function (cb, value, meta) {
              var input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');
              input.onchange = function () {
                var file = this.files[0];

                var reader = new FileReader();
                reader.onload = function () {
                  var id = 'blobid' + (new Date()).getTime();
                  var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.split(',')[1];
                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);

                  // call the callback and populate the Title field with the file name
                  cb(blobInfo.blobUri(), { title: file.name });
                };
                reader.readAsDataURL(file);
              };

              input.click();
            },
            setup: function (editor) {
              editor.on('init', function (e) {
                // $("#mceu_32").hide();
                $(".mce-branding").hide();
                tinymce.get('content-editor').setContent(self.$data.data);
              });
            }
          });
        } catch (ex) {
          alert(ex);
        }
      },
      saveData(){
        this.mydata = tinymce.get('content-editor').getContent();
      }
    }
  }
</script>

<style scoped>

</style>
